/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

import ReactPattern from "assets/img/pattern_react.png"
import AIImage from "assets/img/artificial_intelligence.png"
import E2ESecurity from "assets/img/security.png"
import ABHAIntegration from "assets/img/abha.png"
import DeviceIntegration from "assets/img/device_integration.png"
import FeaturesHomepage from "assets/img/features.png"

export default function Index() {
  return (
    <>
      <IndexNavbar fixed />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                Your Family's Health, <br/>Our Top Priority
              </h2>
              <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
              Welcome to a new era of health management—seamless, secure, and personalized. Our app simplifies your family's healthcare journey by meticulously tracking vital signs, managing appointments, and securely storing all medical documents in one place. Experience peace of mind, all at your fingertips.
              </p>
              <div className="mt-12">
                <a
                  href="https://forms.gle/ZeYnfnEwWrT9VJcs6"
                  target="_blank"
                  className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-orange-500 active:bg-orange-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Take Survey
                </a>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          // src={require("assets/img/pattern_react.png").default}
          src={ReactPattern}
          alt="..."
        />
      </section>

      <section className="mt-48 md:mt-40 pb-20 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="https://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-10/12 md:w-6/12 lg:w-4/12 px-12 md:px-4 mr-auto ml-auto -mt-32">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500 features-img-div">
                <img
                  alt="..."
                  src={FeaturesHomepage}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="https://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white" style={{
                    fontSize: '36px',
                    textAlign: 'center'
                  }}>
                    Features
                  </h4>
                </blockquote>
              </div>
            </div>

            <div className="w-full md:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i class="fa fa-heartbeat"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Track Vitals
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                      Monitor key health indicators in real-time, including heart rate, blood pressure, and blood sugar levels, ensuring you stay informed about your family's health at every moment.
                      </p>
                    </div>
                  </div>
                  
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i class="fa fa-user-plus"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Add Family Members
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                      Easily integrate family members into your health management plan. Generate a personalized signup link for seamless coordination and shared health tracking.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa fa-medkit"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">Upoad Documents</h6>
                      <p className="mb-4 text-blueGray-500">
                      Securely upload and manage essential health documents like doctor's reports and prescriptions. Our cloud storage ensures your medical information is safe, accessible, and organized. No one other than yourself and your family members can see your data.
                      </p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <i className="fa fa-calendar"></i>
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">
                        Appointments Tracker
                      </h6>
                      <p className="mb-4 text-blueGray-500">
                      Keep your healthcare schedule on track. Our app provides reminders and scheduling features to organize doctor's appointments efficiently, helping you manage your health without the stress of forgotten dates.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto overflow-hidden">
          <div className="flex flex-wrap items-center">
            <div className="w-full md:w-6/12 px-4 mr-auto ml-auto mt-8">
              <div className="justify-center flex flex-wrap relative">
                <div className="my-4 w-full lg:w-6/12 px-4">  
                    <div className="bg-lightBlue-500 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src={AIImage}
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Artificial Intelligence
                      </p>
                    </div>
                  
                    <div className="bg-blueGray-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src={ABHAIntegration}
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        ABHA
                      </p>
                    </div>
                  
                </div>
                <div className="my-4 w-full lg:w-6/12 px-4 lg:mt-16">
                  
                    <div className="bg-yellow-500 shadow-lg rounded-lg text-center p-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src={E2ESecurity}
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Privacy and Security First
                      </p>
                    </div>
                  
                    <div className="bg-red-700 shadow-lg rounded-lg text-center p-8 mt-8">
                      <img
                        alt="..."
                        className="shadow-md rounded-full max-w-full w-16 mx-auto p-2 bg-white"
                        src={DeviceIntegration}
                      />
                      <p className="text-lg text-white mt-4 font-semibold">
                        Device Integrations
                      </p>
                    </div>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-12 md:px-4 ml-auto mr-auto mt-48">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i className="fa fa-puzzle-piece text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Integrations
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
              Our app revolutionizes healthcare management by integrating with advanced technologies. Two-Factor Authentication, end-to-end security, active cleaning of personally identifiable information safeguards your health data with robust security measures. Artificial Intelligence (AI) personalizes your care by adapting to your unique health needs. The Ayushman Bharat Health Account (ABHA) allows you to access India's largest digital health infrastructure. Additionally, syncing with smartwatches allows for holistic health management by providing comprehensive insights into your daily physical activities.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-8 bg-blueGray-200 relative pt-16">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="https://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>
      <Footer />
    </>
  );
}
