/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/Logo.jpg";
// components

export default function Navbar(props) {
  
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="px-2">
            <img src={Logo} alt="" height="auto" width="50px"/>
          </div>
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start left-nav">
            <Link
              to="/"
              className="text-blueGray-700 text-xl font-bold leading-relaxed inline-block mr-4 whitespace-nowrap uppercase"
            >
              Nest Aura
            </Link>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none block"
            }
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto right-nav">
              <li className="flex items-center">
                <a
                  className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-3xl uppercase font-bold"
                  href="https://www.instagram.com/nestaura.ai"
                  target="_blank"
                >
                  <i className="text-blueGray-400 fab fa-instagram text-3xl leading-lg " />
                </a>
              </li>

              <li className="flex items-center">
              <a
                  href="https://forms.gle/ZeYnfnEwWrT9VJcs6"
                  target="_blank"
                >
                  <button
                    className="bg-orange-500 text-white active:bg-orange-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Take Survey
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
